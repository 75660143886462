// extracted by mini-css-extract-plugin
export var designDevelopmentExpertsSection = "n_bm";
export var designDevelopmentServices = "n_bk";
export var designDifferenceBetweenSection = "n_bl";
export var designFeaturedCaseSection = "n_bt";
export var designFullVideoSection = "n_bp";
export var designInsightsSection = "n_bv";
export var designPhaseSection = "n_br";
export var designPrimeSection = "n_bj";
export var designProcessSection = "n_bq";
export var designTestimonialPosSection = "n_bn";
export var domainExpertiseDesign = "n_bs";